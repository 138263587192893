export const styles = {
  field: {
    marginBottom: '15px',
  },
  version: {
    display: 'flex',
    justifyContent: 'end',
    fontSize: '12px',
  },
  checkBox: {
    padding: '12px',
    color: '#929292',
    border: '1px solid',
    borderRadius: '3px',
  },
};
