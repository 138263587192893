import { makeStyles, Theme } from '@material-ui/core';

export const styles = {
  profile: {
    marginLeft: '15px',
    display: 'inline-grid',
    alignItems: 'center',
    cursor: 'pointer',
  },
  userIcon: {
    margin: 'auto',
  },
  horizontalDevider: {
    border: '0',
    borderBottom: '1px solid #ccc',
  },
  dropdown: {
    width: '13px',
    height: '13px',
  },
};

export const useStyles = makeStyles((theme: Theme) => ({
  themeChangeButton: {
    '& .MuiButtonBase-root': {
      position: 'absolute',
      top: '-7px',
    },
  },
  customButton: {
    '& button': {
      backgroundColor: '#1976d2 !important',
      color: '#fff',
      outline: 'none',
      border: '1px solid #1976d2',
      padding: '0px 20px',
      '&:hover': {
        backgroundColor: '#63a4ff !important',
        color: '#fff',
      },
    },
    '& .MuiButton-label': {
      textTransform: 'capitalize',
      outline: 'none',
    },
  },
}));
