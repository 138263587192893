import { ThemeContainer } from '@uplink-shared/layout';
import React, { useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { ModalKeeper } from '@uvgo-shared/modal-keeper';
import MountApp, { IMountApp } from './Apps/MountApp';
import HeaderApp from './Components/Header/Header';
import { AuthStore } from '@uplink-shared/security';

const apps: IMountApp[] = [
  { path: 'vendor', name: '@uplink/vendor' }
];

const App = () => {
  
  const handleClearStorage = async event => {
    if (event.key === null) {
      try {
        await AuthStore.logout();
        window.location.reload();
      } catch (e) {
        window.location.reload();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('storage', handleClearStorage);
    return () => {
      window.removeEventListener('storage', handleClearStorage);
    };
  }, []);

  return (
    <ThemeContainer>
      <HeaderApp />
      <ModalKeeper />
      <Routes>
        {apps
          .filter(x => !x.hideApp)
          .map((app, idx) => (
            <Route key={idx} path={`/${app.path}/*`} element={<MountApp app={app} key={app.name} />} />
          ))}
        <Route path="*" element={<Navigate to="/vendor" />} />
      </Routes>
    </ThemeContainer>
  );
};

export default App;
