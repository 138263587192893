import React from 'react';
import ReactDOM from 'react-dom';
import { EnvironmentVarsStore } from '@uplink-shared/env-store';
import { environmentVars } from './environment.vars';
import App from './App';

// Store Env Variables
new Promise<void>((resolve, reject) => {
  const environmentStore = new EnvironmentVarsStore();
  environmentStore.setVars(environmentVars);
  ReactDOM.render(<App />, document.getElementById('root'));
  resolve();
});
