import { observer } from 'mobx-react';
import React, { FC, ReactNode } from 'react';
import { finalize } from 'rxjs/operators';

import { Switch, withStyles } from '@material-ui/core';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { Dropdown, DROPDOWN_TRIGGER } from '@uvgo-shared/dropdown';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { THEMES } from '@wings-shared/core';
import { ENVIRONMENT_VARS, EnvironmentVarsStore } from '@wings-shared/env-store';
import { RootDataStore, ConfirmDialog, ThemeStore } from '@uplink-shared/layout';
import { AuthStore } from '@uplink-shared/security';
import ModuleStore from '../../Stores/Module.store';
import { MultiHeader } from '../MultiHeader';
import { styles, useStyles } from './Header.styles';
import UserProfile from '../UserSettings/UserProfile';
import { DropdownItem } from '@uplink-shared/form-controls';
import { UserProfileIcon } from '@uvgo-shared/icons';

interface IProps {
  [key: string]: any;
}

const HeaderApp: FC<IProps> = (props: IProps) => {
  const env = new EnvironmentVarsStore();
  const classes = useStyles();

  const dropdownOptions = (): ReactNode => {
    const isLightTheme = ThemeStore.currentTheme === THEMES.LIGHT;
    return (
      <React.Fragment>
        <DropdownItem isHeaderItem={true} onClick={() => ModalStore.open(<UserProfile />)}>
          User Profile
        </DropdownItem>
        <DropdownItem isHeaderItem={true} isSubtitle>
          <div className={classes.themeChangeButton}>
            <span>Dark Mode</span>
            <Switch
              checked={isLightTheme ? false : true}
              color="primary"
              inputProps={{ 'aria-label': 'controlled' }}
              onChange={() => ThemeStore.toggleTheme()}
            />
          </div>
        </DropdownItem>
        <hr style={styles.horizontalDevider} />
        <DropdownItem isHeaderItem={true} isRed onClick={getLogoutConfirmation}>
          Logout
        </DropdownItem>
        {/* <DropdownItem isHeaderItem={true} onClick={() => ThemeStore.toggleTheme()}>
          {isLightTheme ? 'Dark Mode' : 'Light Mode'}
        </DropdownItem> */}
      </React.Fragment>
    );
  };

  const userDropdown = () => {
    return (
      <React.Fragment>
        <Dropdown popperContent={dropdownOptions()} trigger={DROPDOWN_TRIGGER.CLICK} autoclose={false}>
          <div style={styles.profile}>
            <span style={styles.userIcon}>
              <UserProfileIcon />
            </span>
            <div>
              <span>{AuthStore.user?.name}</span>
              <ArrowDropDownOutlinedIcon style={styles.dropdown} />
            </div>
          </div>
        </Dropdown>
      </React.Fragment>
    );
  };

  const getLogoutConfirmation = (): void => {
    ModalStore.open(
      <div className={classes.customButton}>
        <ConfirmDialog
          title="Confirm Logout"
          message={'Are you sure you want to logout from this account?'}
          yesButton="Logout"
          noButton="Cancel"
          onNoClick={() => ModalStore.close()}
          onYesClick={() => {
            onLogout();
            ModalStore.close();
          }}
        />
      </div>
    );
  };
  const onLogout = (): void => {
    AuthStore.logout()
      .pipe(finalize(() => ThemeStore.setDefaultTheme()))
      .subscribe();
    RootDataStore.removeLocation();
  };

  return (
    <MultiHeader
      // uvGoLink={env.getVar(ENVIRONMENT_VARS.UVGO_WEBSITE)}
      links={ModuleStore.menuList}
      rightSide={userDropdown()}
    />
  );
};

export default withStyles(styles)(observer(HeaderApp));
