import { ENVIRONMENT_VARS } from '@uplink-shared/env-store';

const ENV = ENVIRONMENT_VARS;

export const environmentVars: Record<ENVIRONMENT_VARS, string> = {
  [ENV.OKTA_URL]: process.env.REACT_APP_OKTA_URL || '',
  [ENV.OKTA_CLIENT_ID]: process.env.REACT_APP_OKTA_CLIENT_ID || '',
  [ENV.OKTA_AUTHORIZATION_SERVER_ID]: process.env.REACT_APP_OKTA_AUTHORIZATION_SERVER_ID || '',
  [ENV.UVGO_WEBSITE]: process.env.REACT_APP_UVGO_WEBSITE || '',
  [ENV.AG_GRID_LICENSE_KEY]:process.env.REACT_APP_AG_GRID_LICENSE_KEY||'',
  [ENV.HIDDEN_MODULES]: '',
  [ENV.VENDOR_MANAGEMENT_CORE_API]: process.env.REACT_APP_VENDOR_MANAGEMENT_CORE_API || '',
  [ENV.VENDOR_MANAGEMENT_NOSQL_API]: process.env.REACT_APP_VENDOR_MANAGEMENT_NOSQL_API || '',
  [ENV.OCP_APIM_SUBSCRIPTION_KEY]:process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY || '',
  [ENV.UPLINK_BASE_URL]:process.env.REACT_APP_MF_HOST_URL || '',
  [ENV.PUSH_NOTIFICATIONS_URL]:process.env.REACT_APP_PUSH_NOTIFICATIONS_URL || '',
  [ENV.AZURE_INSTRUMENTATION_KEY]:process.env.REACT_APP_AZURE_INSTRUMENTATION_KEY || '',
  [ENV.HOST_ENVIRONMENT]:process.env.REACT_APP_HOST_ENVIRONMENT || '',
  [ENV.VENDOR_MANAGEMENT_SUBSCRIPTION_KEY]:process.env.REACT_APP_VENDOR_MANAGEMENT_SUBSCRIPTION_KEY || '',
  [ENV.REFERENCE_DATA_BASE_API]:process.env.REACT_APP_REFERENCE_DATA_BASE_API || '',
  [ENV.UPLINK_OKTA_URL]:process.env.REACT_APP_UPLINK_OKTA_URL || '',
  [ENV.UPLINK_SUBSCRIPTION_KEY]:process.env.REACT_APP_UPLINK_SUBSCRIPTION_KEY || '',
  [ENV.MAPBOX_API_TOKEN]: process.env.REACT_APP_MAPBOX_API_TOKEN || '',
  [ENV.MIXPANEL_KEY]:process.env.REACT_APP_MIXPANEL_KEY || '',
  [ENV.NODE_ENV]:process.env.NODE_ENV || '',
  [ENV.USER_MANAGEMENT_BASE_API]: process.env.REACT_APP_USER_MANAGEMENT_BASE_API || '',
};
