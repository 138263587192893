import { observer } from 'mobx-react-lite';
import React, { FunctionComponent, useState, useEffect, useMemo } from 'react';

import { IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { PinIcon, PinVerticalIcon, UvGoIcon, PhoneIcon } from '@uvgo-shared/icons';
import { UIStore, Utilities } from '@wings-shared/core';

import Logo from './logo.png';
import MainMenuButton from './MainMenuButton';
import { MainNavLink } from './MainNavLink';
import { styles } from './MultiHeader.styles';
import { IMainNavLink } from './types';
import { useLocation } from 'react-router';

interface Props {
  links: IMainNavLink[];
  classes: { [name: string]: string };
  // uvGoLink: string;
  rightSide?: React.ReactNode;
  logoDoubleClick?: (e: React.MouseEvent<HTMLImageElement>) => void;
  exact?: boolean;
  delay?: number;
}

const MultiHeader: FunctionComponent<Props> = observer(props => {
  const [ isHeaderFocused, setIsHeaderFocused ] = useState(false);
  const [ activeModule, setActiveModule ] = useState<IMainNavLink>();

  const location = useLocation();

  const currentDate = new Date();
  // const currentNewDate = Date.parse(`${currentDate.getFullYear()}-${currentDate.getMonth()}`);

  // On Page Refresh setup Active module from browser location
  useEffect(() => {
    const basePath = location.pathname.split('/').filter(Boolean)[0];
    const activeModule = props.links.find(x =>
      Array.isArray(x.childLinks) ? x.childLinks.some(y => Utilities.isEqual(y.to, `/${basePath}`)) : false
    );
    setActiveModule(activeModule);
  }, [ location.pathname ]);

  // Render Modules Links
  const renderModules = () => {
    if (!props.links.length) {
      return null;
    }

    return props.links
      .filter(l => l.isVisible)
      .map((link: IMainNavLink, i: number) => {
        return (
          <div style={{ height: 'fit-content' }} onClick={e => setActiveModule(link)} key={i}>
            {link.isMenuButton ? (
              <MainMenuButton {...link} hasActiveClass={activeModule?.to === link.to} classes={props.classes} key={i} />
            ) : (
              <MainNavLink {...link} exact={props.exact} classes={props.classes} key={i} />
            )}
          </div>
        );
      });
  };

  // Render Child Apps For current activeModule Module
  const renderApps = () => {
    if (!activeModule || !Array.isArray(activeModule?.childLinks)) {
      return null;
    }

    return activeModule.childLinks
      .filter(l => l.isVisible)
      .map((link: IMainNavLink, i: number) => (
        <MainNavLink {...link} exact={props.exact} classes={props.classes} key={i} />
      ));
  };

  return (
    <div className={props.classes.headerWrapper} onMouseLeave={() => setIsHeaderFocused(false)}>
      <header className={props.classes.header}>
        <div className={props.classes.headerLeft}>
          <img
            className="main-nav-logo"
            src={Logo}
            alt="Universal Weather and Aviation, Inc."
            onDoubleClick={event => props.logoDoubleClick(event)}
          />
        </div>

        {/* <div className={props.classes.headerCenter}>
          <div className={props.classes.headerNavlinks} onMouseEnter={() => setIsHeaderFocused(true)}>
            {renderModules()}
          </div>
        </div> */}

        <div className={props.classes.headerRight}>
          {/* <div className={props.classes.menuButton}>
            <a target="_blank" href={props.uvGoLink}>
              <div className={`${props.classes.menuButton}__icon`}>
                <UvGoIcon size="x-large" />
              </div>
              <div className={`${props.classes.menuButton}__text`}>UVGo</div>
            </a>
          </div> */}
          <div className={props.classes.dateTimeContactWrapper}>
            <span>
              {
                currentDate.toDateString()
              }
            </span>
          </div>
          {props.rightSide}
        </div>
      </header>

      {/* {(isHeaderFocused || UIStore.isHeaderExpanded) && (
        <div className={UIStore.isHeaderExpanded ? props.classes.subHeaderExpanded : props.classes.subHeader}>
          <div className={props.classes.subHeaderLeft}></div>

          <div className={props.classes.subHeaderCenter}>{renderApps()}</div>

          <div className={props.classes.subHeaderRight}>
            <div className={props.classes.pinButtonWrapper}>
              <IconButton color="primary" onClick={() => UIStore.setIsHeaderExpanded()} aria-label="pin sub navigation">
                {UIStore.isHeaderExpanded ? <PinVerticalIcon size="x-large" /> : <PinIcon size="x-large" />}
              </IconButton>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
});

MultiHeader.defaultProps = {
  logoDoubleClick: () => null,
  links: [],
  exact: false,
};

export default withStyles(styles, { name: 'layout-shared' })(MultiHeader);
export { MultiHeader as PureMultiHeader };
